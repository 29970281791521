import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// import {css} from "@emotion/react"
import Layout from "/src/layout/default"
import Meta from "/src/components/Meta"
import TopNews from "/src/components/TopNews"
import BusinessHours from "/src/components/BusinessHours"
import Map from "/src/components/Map"
import * as indexStyle from "./index.module.css"


// markup
const IndexPage = ({data, location}) => {
  // function onClick(e) {
  //   e.preventDefault()
  //   const href = e.target.getAttribute('href')
  //   const target = href === '#' || href === '' ? '' : document.getElementById(href.replace('#', ''))
  //   const targetPosition = target === '' ? 0 : window.scrollY + target.getBoundingClientRect().top
  //   window.scrollTo({top: targetPosition, behavior: 'smooth'})
  // }

  return (
    <Layout location={location}>
      <Meta title="平安堂こころのクリニック" description="平安堂こころのクリニックは、津田沼駅の心療内科・精神科・児童精神科です。専門外来に、子どもの発達外来、強迫症外来を設けています。患者さまに安心して帰っていただけるこころ安らぐクリニックを目指しています。" >
        <meta name="format-detection" content="telephone=no" />
      </ Meta>
      <div className={indexStyle.kv}>
        <div className={indexStyle.kvItem}>
          <StaticImage className="hidden-xs" src="../images/kv/kv-01.jpg" quality={95} alt="" loading="eager" placeholder="none" />
          <StaticImage className="visible-xs" src="../images/kv/kv-01-sp.jpg" quality={95} alt="" loading="eager" placeholder="none" />
        </div>
        <div className={indexStyle.kvItem}>
          <StaticImage className="hidden-xs" src="../images/kv/kv-02.jpg" quality={95} alt="" loading="eager" placeholder="none" />
          <StaticImage className="visible-xs" src="../images/kv/kv-02-sp.jpg" quality={95} alt="" loading="eager" placeholder="none" />
        </div>
      </div>
      <div className={indexStyle.nav}>
          <ol className={indexStyle.navList}>
            {/* eslint-disable-next-line*/}
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/news/">お知らせ</Link></li>
            <li><Link to="/consultation/">診療のご案内</Link></li>
            <li><Link to="/consultation/#interviewSheet">問診票</Link></li>
            <li><Link to="/access/">診療時間・アクセス</Link></li>
            <li><Link to="/about/">当院について</Link></li>
          </ol>
      </div>
      <p className={indexStyle.lead}>こころのつらさや悩みに寄り添い、<br />ともに歩んでいきます。</p>
      <TopNews />
      
      <div className={indexStyle.group2}>
        <div>
          <h2 id="top-2" className={indexStyle.cHeading1}><span>診療のご案内</span></h2>
          <ul className={indexStyle.group2List}>
            <li className={indexStyle.group2Item}><Link className={indexStyle.group2Link} to="/consultation/#consultation1">児童精神科</Link></li>
            <li className={indexStyle.group2Item}><Link className={indexStyle.group2Link} to="/consultation/#consultation2">成人精神科・心療内科</Link></li>
          </ul>
          <h2 className={indexStyle.group2Heading}><span className={indexStyle.group2Heading_inner}>専門外来</span></h2>
          <ul className={indexStyle.group2List}>
            <li className={indexStyle.group2Item2}><Link className={indexStyle.group2Link} to="/consultation/#specialty1">子どもの発達外来</Link></li>
            <li className={indexStyle.group2Item2}><Link className={indexStyle.group2Link} to="/consultation/#specialty2">強迫症外来</Link></li>
          </ul>
          <ul className={indexStyle.group2Annotation}>
            <li className={indexStyle.group2AnnotationItem}>＊15歳以下のお子様の受診では、十分な診療を行うため、保険診療による自己負担金に加え、初診時のみ予約料5,500円を頂戴しています。</li>
            <li className={indexStyle.group2AnnotationItem}>＊療育や４歳以下のお子様の発達についての相談は行っておりません。お住まいの自治体の発達相談センター等にご相談ください。</li>
          </ul>
        </div>
      </div>

      <div className={indexStyle.bg}>
        <div className={indexStyle.businessHours}>
          <h2 id="top-4" className={indexStyle.cHeading1}><span>診療時間</span></h2>
          <BusinessHours data={data.microcmsBusinessHours} />
        </div>
      </div>
        <div className={indexStyle.map}>
          <h2 id="top-5" className={indexStyle.cHeading1}><span>アクセス</span></h2>
          <Map />
        </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    microcmsBusinessHours {
      id
      hours {
        day
        fieldId
        hours
      }
    }
    pdf: file(
      sourceInstanceName: {eq: "static"}
      relativePath: {eq: "files/monshinhyo.pdf"}
    ) {
      publicURL
    }
  }
`