import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import * as topNewsStyle from './TopNews.module.css'

export default function TopNews() {
    const data = useStaticQuery(graphql`
        {
            allMicrocmsNews(sort: {fields: publishedAt, order: DESC}, limit: 3) {
                totalCount
                nodes {
                    id
                    publishedAt
                    rich_content
                    title
                }
            }
        }
    `)
    return (
        <div className={topNewsStyle.bg}>
            <div className={topNewsStyle.container}>
                <h2 id="top-1" className={topNewsStyle.title}><span>お知らせ</span></h2>
                <ul className={topNewsStyle.list}>
                {data.allMicrocmsNews.nodes.map(({id,publishedAt,title}) => {
                    const date = new Date(publishedAt)
                    const dateString = date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate()
                    return (
                    <li key={id}>
                        <article className={topNewsStyle.article}>
                        <p className={topNewsStyle.date}><time dateTime={ publishedAt }>{ dateString }</time></p>
                        <h2 className={topNewsStyle.articleTitle}><Link to={'/news/#news-' + id}>{ title }</Link></h2>
                        </article>
                    </li>
                    )
                })}
                </ul>
            </div>
        </div>
    )
}
